import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const MasteringPage = () => {
  return (
    <PageLayout>
      <SEO
        title="LUFS and The Loudness War | Mixing Mastering Levels | Jimmy Ether"
        ogDescription="For decades mastering engineers have fought The Loudness Wars. I deep-dive into why it happenend, if there is any merit, if it is actually over, and how I approach masterting today for streaming and HD."
        image="https://jimmyether.com/images/lufs-and-the-loudness-war-sausage.jpg"
        url="https://jimmyether.com/lufs-and-the-loudness-war/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>LUFS and The Loudness War</h1>
            <p>
              The term LUFS stands for Loudness Units Relative to Full Scale. It is a synonym for LKFS used in the motion picture industry. The motion picture and broadcast industry has enforced loudness standards by way of the <a href="https://www.fcc.gov/media/policy/loud-commercials" target="_blank">CALM Act</a> since 2011.
            </p>
            {/*<ScrollToButton
              id="video"
              content="Watch Video"
              style={{ marginBottom: "20px" }}
            />*/}
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-sausage.jpg" alt="LUFS and The Loudness War Sausage Waveform" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/lufs-and-the-loudness-war-executive.jpg"
              alt="The Loudness War Caused By Executives"
            />
            <Blockquote>
              The loudness thing is ridiculous ... People have volume controls ... The loudness thing is just for A&R guys who come in and play their records around a table at an A&R meeting, and they want theirs to jump out more than anybody else’s. But that’s the only time that it’s important. - <a href="https://tapeop.com/interviews/151/jesse-ray-ernster-bob-clearmountain-mixing/" target="_blank">Bob Clearmountain</a>
            </Blockquote>
          </div>
        }
        textRight={
          <div>
            <h2>Why is Loudness a thing? Why did it happen?</h2>
            <p>
              The trend in popular music has continued to push for "louder" audio levels since the 1990s. This choice was seldom based on quality. Instead, it was about marketing and promotion. Specifically, A&R reps and indie radio promoters felt they had an advantage if they could make their song louder. They thought it would get the attention of executives and music directors. This led to an arms race we affectionally call The Loudness War.
            </p>
            <p>
              But this loudness war has one obvious conclusion. There is a literal ceiling of 0dBFS - the highest possible level on digital equipment. And the closer you push music to that ceiling the less dynamic range you have available.
            </p>
            <p>
              With certain genres, a restricted dynamic range may be okay because the style is a hyper-compressed and "crushed" sound. But there is a point where it becomes absurd, and we've long passed that point. Plus, you can achieve the effects of a crushed master without sacrificing dynamic range.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Consumer Electronics Cut Corners</h2>
            <p>
              As music became less and less dynamic, the low end of consumer audio preyed on the situation. They could now spend less on crucial components like amplifiers and speaker design. If the music is less dynamic, less energy needs to go into a speaker.
            </p>
            <p>
              This compounded the issue of consumers preferring "louder" masters. Their systems weren't capable of reproducing high dynamic content. Poor quality systems made things sound dark and muddy. So, we had a negative feedback loop going. Loud masters influenced manufacturers which in turn influenced listeners. Around and around again. And over nearly 30 years things have trended louder and louder beyond the point of no return.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-electronics.jpg" alt="The Loudness War and Consumer Electronics" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/lufs-and-the-loudness-war-move-air.jpg"
              alt="Move Air to move derrière"
            />
            <Blockquote>
              But the main reason dynamics are important to me is that dynamics move air. Sound is moving air. And the more you move, the more impactful the sound is. It's why seeing fireworks live is thrilling. That sonic boom is moving a lot of air. Dynamics are what get a speaker cone moving. Literally pushing air. Move Air to move derrière.
            </Blockquote>
          </div>
        }
        textRight={
          <div>
            <h2>Why is reducing dynamic range a bad thing?</h2>
            <p>
              The important thing to remember about digital is that it eventually becomes analog. If you are listening to music on speakers or headphones, you are now in the analog world. No human hears digital ones and zeros. And so, the physics of sound applies to you. Without getting too deep into it, certain Digital to Analog converters might be adversely affected by loud masters. Or at least protect themselves from clipping - adversely affecting the sound.
            </p>
            <h3>Move Air to Move Derrière</h3>
            <p>
              But the main reason dynamics are important to me is that dynamics move air. Sound is moving air. And the more you move, the more impactful the sound is. It's why seeing fireworks live is thrilling. That sonic boom is moving a lot of air. Dynamics are what get a speaker cone moving. Literally pushing air. And it's particularly important when experiencing low frequencies. Reproducing low frequencies accurately requires dynamics to exist in the recording. Often, when hyper-compressing a master, you will find they achieve it by cutting or aggressively reigning in those lower frequencies. This makes for a very conservative and bland listening experience. Especially on anything better than a computer or phone speaker.
            </p>
            <p>
              With certain genres, a restricted dynamic range may be okay because the style is a hyper-compressed and "crushed" sound. But there is a point where it becomes absurd, and we've long passed that point. Plus, you can achieve the effects of a crushed master without sacrificing dynamic range.
            </p>
            <h3>The Loss of Musical Complexity</h3>
            <p>
              The other thing that happens with hyper-limited masters is they lose depth in the stereo spectrum. Limiting pushes the loudest sounds down to the same level as the quietest sounds. Then they apply makeup gain to bring the true peak closer to 0. So, with heavy limiting, everything is in your face. Because of this, songs intended for this extreme limiting often sacrifice not only dynamic range. They abandon complexity in instrumentation. For some kinds of music, this may be fine. But you shouldn't consider it a standard approach.
            </p>
            <p>
              The long and the short of it for me: I don't want anyone dictating what kind of musical choices I make. Especially not based on what I hope I'll show below to be very flawed assumptions.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>LUFS vs RMS Loudness Measurement</h2>
            <p>
              First, let's clarify the measurement side of this. We have to define a common language and reliable measurement when talking about loudness. We've had many forms of digital metering for decades. But when it comes to perceived loudness, there are two options: RMS and LUFS.
            </p>
            <p>
              RMS stands for Root Mean Square. It was an alternative to peak metering to help judge the loudness of a signal. This is the basis of the K-System I will discuss later.
            </p>
            <p>
              LKFS (commonly called LUFS in the music world) is an adaptation of RMS. The motion picture industry created it to take into account the human perception of loudness. It is RMS with equalization added to account for how our ears (and brains) respond to sound intensity across a range of frequencies.
            </p>
            <h3>Three Types of LUFS</h3>
            <p>
              To make things more confusing, there are three types of LUFS readings. Momentary, Peak, and Integrated. These often trip people up when talking about their loudness settings. When someone tells you they "master to -6 LUFS" you should ask for clarification. Do they mean peak, momentary or integrated?
            </p>
            <h4>Momentary, Peak, and Integrated LUFS</h4>
            <p>
              Momentary is useful for checking a specific part of a song. Peak level is useful for seeing the absolute loudest part of a song or entire release. Integrated takes an average across time. We care most about streaming services and the normalization penalty they impose. They tend to use integrated LUFS across the entire release. 
            </p>
            <p>
              Why do we care so much about LUFS? Because streaming services use different approaches for dealing with normalizing music.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>How Most Listeners Consume Music Today</h2>
            <p>
              Should streaming services dictate how we produce music? No. But you should always be aware of how the end users experience your music. Going by the data, streaming is an overwhelming percentage of the market today.
            </p>
            <p>
              <a href="https://www.ifpi.org/our-industry/industry-data/" target="_blank">According to a survey from IFPI</a>, 22% of music listening is video streaming over YouTube. 32% of listening comes from the streaming services. That's well over half, and that will likely increase. 
            </p>
            <h2>
              Streaming As A Positive Influence on Sound Quality
            </h2>
            <p>
              Here's what's interesting and beneficial to music in the long run. Streaming services care about giving listeners a good experience. So they are normalizing the volume of songs for most of their listeners by default. This means the volume isn't jumping around when they listen to playlists. Or if they jump from a 60s-era record to Skrillex, their head isn't getting blown off. And since most listeners are going to hear normalized tracks, there is no longer a need to push for loudness. At least, not above a certain point. We only need to decide what that logical point is.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-streaming.jpg" alt="LUFS and The Loudness War Streaming Services DSPs" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Different Loudness Normalization Standards</h2>
            <p>
              Unlike the film and broadcast standard, there is no government-mandated standard for loudness with music. But a couple of organizations have proposed two when it comes to streaming.
            </p>
            <h3>Audio Engineering Society (AES)</h3>
            <p>
              AES began in 1948 and provides many of the technical standards for audio used all over the world. They have proposed a few streaming standards which have evolved over the years into the current <a href="https://www.aes.org/technical/documentDownloads.cfm?docID=731" target="_blank">AESTD1008.1.21-9 whitepaper</a>. This sets -16 Integrated LUFS as the target.
            </p>
            <h3>International Telecommunication Union (ITU)</h3>
            <p>
              The ITU is an agency of the United Nations responsible for matters related to technology and communications. They proposed <a href="https://www.itu.int/dms_pubrec/itu-r/rec/bs/R-REC-BS.1770-4-201510-I!!PDF-E.pdf" target="_blank">ITU-R BS.1770</a>. This sets -14 Integrated LUFS as the target.
            </p>
            <h2>
              Do Streaming Streaming Platforms Boost Quiet Songs?
            </h2>
            <p>
              Most services adopt the AES recommendation that normalization should only reduce the gain. Never increase it. 
            </p>
            <p>
              Spotify is the notable exception to this rule. They will apply "positive gain" to a track based on its true peak. But that shouldn't adversely affect the audio quality. They are only eliminating unused headroom. That said, there is a "Loud" setting for premium users listening in noisy environments. That setting will apply a limiter in the app.
            </p>
            <h2>Streaming Platforms and Their Normalization Practices</h2>
            <h3>Spotify</h3>
            <p>
              Spotify is the most complicated, so let's hit that first. They have free ad-supported users which fall into two categories: app and web browser. For web browsers, at the time of this writing, Spotify applies no normalization. For both desktop and mobile apps, normalization is on by default. 
            </p>
            <p>
              They have 3 settings for Premium (paid) users: Loud, Normal, and Quiet.
            </p>
            <p>
              Spotify offers the <em>Loud</em> setting for people listening in noisy environments. This sets the volume of all tracks to -11 Integrated LUFS regardless of their true peak. They do this by using a limiter, which will affect the final sound and is out of your control.
            </p>
            <p>
              The <em>Normal</em> setting is the default, and it is set to -14 Integrated LUFS. If a track is quieter than that, they will apply positive gain raising the true peak to -1dB, but this change should not adversely affect the sound. They simply turn down anything above -14 Integrated LUFS. Something mastered at -8 Integrated LUFS would get reduced by 6dbB.
            </p>
            <p>
              The <em>Quiet</em> setting is for people who use Spotify through theater systems. Spotify reduces all songs to -19 Integrated LUFS. That's 1 dB higher than Dolby's own recommended -18 Integrated LUFS for Atmos. Close enough.
            </p>
            <p>
              One critical thing to note is that Spotify uses the lossy Ogg Vorbis encoding. Due to artifacts with that encoding process, they recommend having a maximum true peak of -1dB for tracks mastered to -14 Integrated LUFS. Tracks mastered any louder than that should use -2dB true peak - taking away yet another dB of dynamic range.
            </p>
            <p>
              You can find the documentation for Spotify here: <a href="https://artists.spotify.com/en/help/article/loudness-normalization" target="_blank">https://artists.spotify.com/en/help/article/loudness-normalization</a>
            </p>
            <h3>YouTube/YouTube Music</h3>
            <p>
              YouTube normalizes to -14 Integrated LUFS with a true peak of -1dB. This is done upon upload and encoding, so there is no way to turn it off.
            </p>
            <h3>Apple Music</h3>
            <p>
              Apple is the only major service using the <a href="https://www.aes.org/technical/documentDownloads.cfm?docID=731" target="_blank">AES TD1008</a> standard. They therefore normalize to -16 Integrated LUFS with a true peak of -1dB. Their High Quality lossy setting uses AAC at 256 kbps. 
            </p>
            <h3>Tidal</h3>
            <p>
              Tidal is normalized to -14 Integrated LUFS. Tidal's Normal setting is lossy using AAC encoding at 160 kbps.
            </p>
            <h3>Amazon</h3>
            <p>
              Normalized to -14 Integrated LUFS with a true peak of -1dB.
            </p>
            <h3>Deezer</h3>
            <p>
              Deezer seems to use -14 Integrated LUFS with a true peak of -2db. They encode to MP3 for anything below their lossless High Fidelity setting (which is FLAC).
            </p>
            <h3>Pandora</h3>
            <p>
              Pandora seems to normalize at -13.5 Integrated LUFS but uses a more conservative -2.4dB true peak.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>All the Arguments Against -14 Integrated LUFS</h2>
            <Blockquote>
              The major labels master loud, so that's good enough for me!
            </Blockquote>
            <p>
              I'll never understand this keeping up with the Joneses mentality. Because major labels release louder albums doesn't make it a viable choice. The industry as a whole has copied poor major-label decisions for decades in the face of the data. Maybe my punk mentality is overriding all else, but I couldn't care less what a major label does.
            </p>
            <p>
              Why would mastering engineers default to making loud masters? I guarantee with most that's been a surrendered point due to attrition. Ultimately, the client dictates the result. With labels, the marching orders are coming from A&R and promotion folks who don't have a clue about audio. Or it's coming from the artist who thinks they have to compete for loudness. You can advise to the contrary. But ultimately, you relent if you want to have a business.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-arguments.jpg" alt="LUFS and The Loudness War Arguments" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <Blockquote>
              I have to be loud to compete!
            </Blockquote>
            <p>
              Compete with who? Is there some marketplace I'm unaware of that rewards you based on the perceived loudness of your track? Is there any data showing causation or correlation between loudness and hit factor? No. A song is good or it isn't. A song has promotion money behind it or it doesn't. And the latter is going to be the better bet for having the greatest effect. A few LUFS isnt.
            </p>
            <Blockquote>
              People turn off normalization.
            </Blockquote>
            <p>
              I'll grant you that there isn't conclusive data out there that shows what percentage of users turn off normalization. But we can get a rough idea based on the IFPI data. We know that 22% listen over YouTube streams, so that is all normalized. We know that 23% listen to premium streaming services. And that these services have normalization on by default. Let's be very conservative and guess that at least 50% of those users leave it on. And then there's the ad-supported 9%. We can go with the conservative estimate that 50% of those are using an app with normalization on by default.
            </p>
            <p>
              So on the low side, we're looking at 38% of listeners using normalization. I think it's much higher. Especially taking into account that "Sound Check" normalization for MacOS and iOS users is on by default for new installs. That's even on the files you play from your own library. Also, a good 32% of the other listeners were using radio, live music, social media or other forms outside of their control. So, likely, 16% or fewer are likely to be listening with normalization turned off.
            </p>
            <p>
              But what about those (like me) that do have normalization turned off? Well, they know how to use a volume knob. I leave it off when I'm actively listening to albums. And I adjust my volume accordingly. If I'm listening to playlists or have music on in the background, I'll switch it back on. Let's give our power-users the respect they deserve and assume they can use a volume knob.
            </p>
            <Blockquote>
              My specific EDM sub-genre requires a loud master.
            </Blockquote>
            <p>
              This might be valid. It's weird! But it could be legit. Using Dubstep as an example, what you're saying is:
            </p>
            <ul>
              <li>At -6 Integrated LUFS this is Dubstep</li>
              <li>At -8 Integrated LUFS this is still Dubstep</li>
              <li>At -10 Integrated LUFS this is NOT Dubstep</li>
            </ul>
            <p>
              If that's true, I have two questions. First, what genre is it at lower levels? Second, who makes these rules? Is there an amendment process? Okay, that's three questions.
            </p>
            <p>
              In the dance genre, I would think you would want to take advantage of every sonic tool you had to affect people. Dynamics is a powerful tool to abandon. But if that's how you define the genre, I get it.
            </p>
            <p>
              I thought Norwegian Black Metal was strange for their sonic choices too. But I can't deny that the genre had a particular charm because of it. Everyone is free to make the craziest music with the craziest rulesets they want. But let's not impose those rules on the entire world of audio.
            </p>
            <Blockquote>
              Loud masters are necessary for the club.
            </Blockquote>
            <p>
              I get that this seems counterintuitive, but heavily limited material simply can't sound better on big systems. Dynamics are what move air. Moving air is how we experience impactful sound. A high-powered PA with large speakers can push a lot of air, but only if the program material has the dynamics to push it. Remove those dynamics, and there is less movement of woofers. If you want to have a massive impact in a club, use the dynamics you have available.
            </p>
            <p>
              Not to mention that DJs play music from all eras and sourced from many formats. They have channel faders for a reason. Part of what they do is to balance track volume. If you want an edge in the club, bring more dynamic mixes and stems. You'll blow the heavily limited stuff away.
            </p>
            <Blockquote>
              It sounds fuller on computer and phone speakers.
            </Blockquote>
            <p>
              Yes, this could be true in certain circumstances. The tiny computer speakers and cell phone speakers aren't capable of producing the full range of frequencies and dynamics. But do we want to focus the quality of our music on the lowest common denominator? I don't. I want it to sound good everywhere. But I want it to sound amazing on quality systems. You make your own choices.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Loudness with HD Releases</h2>
            <p>
              Now, time for a rant. If you are a label offering High Definition downloads on sites like HD Tracks, listen up. Those masters should not be the same master as the streaming one! Nothing makes me angrier than falling in love with a record and buying the expensive HD version to find it's mastered to -8 Integrated LUFS. It defeats the purpose of getting a lossless 24-bit file if there are no dynamics in the master.
            </p>
            <p>
              Let's hope that most of these labels aren't complete morons and at least do a custom master for their vinyl releases. Use the vinyl master for your HD master! This isn't that difficult. The same goes for Atmos releases. Use the vinyl pre-master.
            </p>
            <p>
              If there is a day when I can choose between -14 Integrated LUFS standard resolution or -18 Integrated LUFS high definition 24-bit version, I'll be happy. I'm not holding my breath. But I am working on a way of providing that for our fans.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-high-def.jpg" alt="LUFS and The Loudness War and High Definition Audio" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>My Conclusions</h2>
            <p>
              I'm currently going back through my 30 years of releases to remix, remaster, and re-release. So this argument about mastering levels could not have come at a better time. And based on everything I've discussed, I've determined the following standards for myself. And you are welcome to use them yourself.
            </p>
            <h3>A Practical Array of Loudness Choices for MASTERING</h3>
            <h4>The Extreme</h4>
            <p>
              -11 Integrated LUFS with -2dB True Peak - the loudest practical level giving you only 9dB of dynamic range. That's not a lot. But it may be enough for some pop, hip-hop, bro-country, and dance. At least those that don't take advantage of depth and are aggressively controlling low frequencies.
            </p>
            <h4>The Standard</h4>
            <p>
              -14 Integrated LUFS with -1dB True Peak - this is where most streaming services will normalize. This gives you 13dB of dynamic range. Which is more than enough to deal with most pop, hip-hop, rock, metal, jazz, country, and bluegrass. This is what I would submit to streaming services. And make available for lossless download.
            </p>
            <h4>The High Definition</h4>
            <p>
              -18 Integrated LUFS with a -0.1dB True Peak. I'm going with Dolby's own Atmos recommendations on this one. This is what I would provide for a High Definition release in 24bit and 96kHz or better sampling rate. It's also what I provide for vinyl masters.
            </p>
            <h4>The Motion Picture Soundtrack</h4>
            <p>
              -24 Integrated LUFS. Which is the standard (by law in the USA) for motion picture and television broadcasts. This is how I would output mixes and stems for use in a film, television, or commercial.
            </p>
            <p>
              <strong>Note:</strong> that doesn't mean the preview track that music supervisors preview for determining they want to use your work should be -24. But it should be how you supply the deliverables once contracted for sync. It's likely way more dynamic range than you'll ever need unless you are composing a score. But it gives you the opportunity to make the most dynamic presentation you can and let the sound team decide how to best use it.
            </p>
            <p>
              But, certain music libraries and sync agencies may have their own specifications. Do what you gotta do.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>What about LUFS when MIXING?</h2>
            <p>
              LUFS, in my opinion, are not as useful in the mixing stage. At least, not with traditional mixing. But the trend with newer producers bucks tradition.
            </p>
            <h3>Mixing for Maximum Loudness</h3>
            <p>
              This is what blew my mind. After chatting with dozens of younger producers I realized how many were building their mixes for extreme limiting. I understand why people do it, but it's misguided. They have bought into this idea that loud masters are necessary (either aesthetically or for marketability). So, they are building compromises into their mixes to deliver an extremely loud mix that sounds clear. This means they are making decisions around musical composition and signal processing to serve a loud digital master.
            </p>
            <p>
              I've already made it clear why loud masters are a poor goal based on faulty reasoning. But what's worse, when these decisions are also baked into the mix, it places a historical limitation on the music. It can only ever be loud. And when that doesn't work with other formats - either currently available or formats that may gain traction in the future - that's a huge opportunity loss. 
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-mixing-levels.jpg" alt="LUFS in Mixing" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/lufs-and-the-loudness-war-vinyl.jpg"
              alt="High LUFS is bad for vinyl"
            />
          </div>
        }
        textRight={
          <div>
            <h2>The Impact of Loudness on Vinyl Releases</h2>
            <p>
              The majority of vinyl masters are cut to lacquer, which has physical limitations. One such factor means vinyl releases don't sound good when they are heavily limited. So, if you are mixing for extrmem limiting, then you are going to have issues when cutting vinyl to lacquer. If you must send a cutting engineer your heavily limited pre-master, I recommend having a direct metal master made. It will retain clarities in the highs without as much distortion, and the more conservative low end of your mix will be represented fairly well. 
            </p>
            <p>
              But what you should be doing for vinyl is a mix and pre-master at -18 Integrated LUFS. This is what I provide to cutting engineers for our releases.
            </p>
            <h2>Dolby Atmos and Future Formats</h2>
            <p>
              This is why mixing for a loud result is short-sighted. A great mix should sound good on any form or media available presently and in the future. You never know what's coming. Dolby Atmos is based around the film and broadcast standard of -24 LKFS. For music, Dolby itself recommends a target of -18 Integrated LKFS for popular music. If you've generated mixes around the goal of -8 Integrated LUFS or higher, those masters will be forever restricted to that sound. 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>What should my final mix levels be?</h2>
            <p>
              Start out with mixes that preserve the maximum amount of dynamic range you'd ever want to use. This will give you the most control over the release now and into the future. You can always pop a limiter on your master bus and preview what your mix will sound like loud as well. But the mix you take to the mastering stage should be a dynamic one. This gives you the most flexibility to output to multiple formats. But it also gives your mastering engineer more to work with when sculpting the aesthetic of your entire release. You can't undo limiting and compression in mastering. You can always add more.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Loudness Metering When Mixing - Bob Katz and the K-system</h2>
            <p>
              I try not to worry too much about LUFS when mixing. Instead, I use the K-System, developed by mastering engineer and AES contributor <a href="https://www.amazon.com/gp/product/0240818962/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i0" target="_blank">Bob Katz</a>. It's RMS metering on a modified scale based on the target loudness. It provides a combined peak and average RMS meter, but the scale changes based on the intended deliverable. Instead of 0 being the digital ceiling, it is scaled more like an analog VU meter that provides a zero target for your average level. But, you can go beyond 0 for the loudest parts of your song. It's extremely easy to read (much like a VU meter) and broken into colored bands. 
            </p>
            <ul>
              <li>Green is quiet.</li>
              <li>Yellow is the target range.</li>
              <li>Red is loud.</li>
              <li>Blue (in some K-System meters) represents clipping.</li>
            </ul>
            <p>
              There are three scales on the Katz system. K-12, K-14, and K-20.
            </p>
            <ul>
              <li>K-12 was initially intended for broadcast before the 2011 CALM Act mandated that broadcast levels be -24 LKFS. You can think of this as "The Extreme" target in the mastering stage using the guidelines I provided. I would not mix at this level.</li>
              <li>K-14 is roughly equal to -14 LUFS, which is "The Standard" target for mastering outlined previously. I would not mix at this level.</li>
              <li>K-20 is for film scores, classical music, and very dynamic jazz in the mastering stage. But, it is also the best meter to use on your master bus in the mixing stage for any genre. This will keep you from clipping, and retain the full dynamic range and depth of your mix.</li>
            </ul>
            <p>
              For most rock, metal, pop, hip hop, and jazz I target my mixes to be between -20dB and -18dB RMS (or a hot K-20 on the Katz scale). And I'll output at 24 bits and usually at a 96k sampling rate or better.
            </p>
            <p>
              If you are using a LUFS meter, this would translate to around -18 Integrated LUFS. If you are doing EDM where the aesthetic is crushed with low dynamic range, then I would provide a reference at whatever hyper-loud level you desire. But send a mix between -18 and -16 Integrated LUFS so the mastering engineer has options.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/bob-katz-k-system.png" alt="Bob Katz K-System Metering" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>What Should I Send My Bandmates or Clients?</h2>
            <p>
              When sending evaluation mixes to my bandmates or clients, I usually toss on a limiter and output at about -14 Integrated LUFS. This gets them in the ballpark of what it will sound like mastered. I don't spend a lot of time on it because at that level we're not adding noticeable clipping artifacts. Some clients are audiophiles who understand what a low-volume mix master should sound like. By all means, send them the unlimited master. But in my experience, most people can't relate to a quieter master like this outside of the classical or jazz world.
            </p>
            <h2>Bonus Round: How you can use integrated LUFS to optimize loudness impact</h2>
            <p>
              Here's a potential trick you can play with if you care about using loudness as an advantage on streaming services. With Spotify (and as best as I can tell this is true with all the other services) they normalize your music based on Integrated LUFS across the entire release. If you only release a single, then the average is only across that song. But if you have an album, an EP, or a maxi-single with bonus material, they will average across the whole release. That's how they determine the levels that will be set into their metadata when normalization is on.
            </p>
            <p>
              I haven't tried this (yet). But let's say you have a single that you want to pop out as louder than other songs on a normalized playlist. Let's say I release that single along with a few additional bonus tracks (associated beats, remixes, etc.). I master the focus single pretty hot. Let's say -11 LUFS with a true peak of -2dB. 9dB of dynamic range is often plenty for a pop track. But, I master the bonus material on the lower side. Maybe -16 LUFS with a true peak of -1dB. So long as the average (Integrated) LUFS across the full release calculates to -14 Integrated LUFS, they should not apply negative gain to my release. So while everyone else's track on a playlist is getting normalized down to -14 LUFS, my focus single is still played at -11 LUFS. Pretty sneaky, Sis.
            </p>
            <p>
              Of course, streaming services can always change their approach in the future. So I'm not suggesting anyone do this hack. But, it does show you how it's possible to build true dynamics into a release that takes advantage of loudness instead of just making everything loud. For a masterclass on this, check out the album Vespertine by Björk. For an interesting example of this for a single song, check out "Settle Down" by Kimbra. It's still mastered too hot, but it's use of dynamics is very effective.
            </p>
          </div>
        }
      />
      {/*<div className="section textured" id="video">
          <VideoPlayer youtubeId='c6J30ummT4M' />
      </div>*/}
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default MasteringPage
